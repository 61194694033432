import { Currency } from 'prices/types'
import { SubscriptionProvider, SubscriptionStatus, SubscriptionTier } from 'utils/membership'

export interface SubscriptionResponse {
	message?: string
	firstName: string
	lastName: string
	email: string
	currentPodSkuName: string
	customerAddress: {
		line1: string
		line2: string
		city: string
		state: string
		country: string
		postalCode: string
	}
	primarySubscription?: SubscriptionData
	userCreatedDate: string
	currentHubVersion: string
	currency: Currency | null
	stripePaymentMethod?: string

	subscriptionInfo: {
		hasBasic: boolean
		churned: boolean
		hasAccessToBasicFeatures: boolean
		hasAccessToPremiumFeatures: boolean
		isUnpaidPremium: boolean
		inGracePeriod: boolean
		capabilities: SubscriptionCapability[]
		displayName?: string
	}
}

export type SubscriptionCapability =
	| 'temperature_control_with_timer'
	| 'device_setup'
	| 'partner_management_and_temperature_control'
	| 'multi_pod_support'
	| 'temperature_scheduling'
	| 'vibration_and_temperature_wake_up'
	| 'sleep_and_health_reporting'
	| 'away_mode'
	| 'member_shop_and_referral'
	| 'autopilot'
	| 'insights'
	| 'sleep_content'
	| 'temperature_control_widget'
	| 'snoring_detection'
	| 'snoring_mitigation'
	| 'five_year_extended_warranty'

export interface SubscriptionData {
	subscriptionId: string
	type: 'premium' | 'basic'
	status: SubscriptionStatus
	createdAt: string
	expiresAt?: string
	willRenew: boolean
	hasBillingFailure: boolean
	inGracePeriod: boolean
	gracePeriodEnd?: string | null
	isUnpaidPremium: boolean
	cancellationReason?: string | null
	subscriptionTier?: SubscriptionTier
	price: number
	subscriptionFrequency: 'monthly' | 'yearly' | 'lifetime'
	lastPaymentDate: string
	provider?: SubscriptionProvider
	currency?: Currency
	subscriptionProductName: string
	isActivePremium?: boolean
	isActiveBasic?: boolean
}

export enum SubscriptionType {
	PRE_SUB_ERA_GRANDFATHERED = 'pre_sub_era_grandfathered',
	OPTIONAL_ERA_GRANDFATHERED = 'optional_era_grandfathered',
	OPTIONAL_ERA_CHURNED = 'optional_era_churned',
	OPTIONAL_ERA_STANDARD = 'optional_era_standard',
	OPTIONAL_ERA_PRO = 'optional_era_pro',
	MANDATORY_ERA_STANDARD = 'mandatory_era_standard',
	MANDATORY_ERA_ENHANCED = 'mandatory_era_enhanced',
	NO_SUBSCRIPTION = 'no_subscription',
	TEMPORARY = 'temporary',
	VIP = 'vip',
	CUSTOM_GRANDFATHERED_TO_MANDATORY = 'custom_grandfathered_to_mandatory',
	CUSTOM_GRANDFATHERED_TO_ENHANCED = 'custom_grandfathered_to_enhanced',
}

export enum SubscriptionFeature {
	TEMPERATURE_SCHEDULE = 'temperature_schedule',
	TEMPERATURE_DIAL = 'temperature_dial',
	AUTOMATIC_TEMPERATURE = 'automatic_temperature',
	SLEEP_AND_HEALTH_REPORTS = 'sleep_and_health_reports',
	SLEEP_AND_HEALTH_INSIGHTS = 'sleep_and_health_insights',
	VIBRATION_AND_THERMAL_ALARM = 'vibration_and_thermal_alarm',
	SNORING_DETECTION = 'snoring_detection',
	SNORING_MITIGATION = 'snoring_mitigation',
	TWO_YEAR_WARRANTY = 'two_year_warranty',
	FIVE_YEAR_WARRANTY = 'five_year_warranty',
	EXTENDED_WARRANTY = 'extended_warranty',
}

export const determineSubscriptionType = (subscription?: SubscriptionResponse): SubscriptionType => {
	if (subscription === undefined) {
		return SubscriptionType.NO_SUBSCRIPTION
	}

	if (!subscription.primarySubscription || !subscription.subscriptionInfo) {
		return SubscriptionType.NO_SUBSCRIPTION
	}

	const subscriptionName = subscription.primarySubscription.subscriptionProductName.toLowerCase() ?? ''

	if (subscriptionName === 'exclusive') {
		return SubscriptionType.VIP
	}

	if (subscription.subscriptionInfo.isUnpaidPremium && subscription.primarySubscription.subscriptionFrequency === 'lifetime') {
		return SubscriptionType.PRE_SUB_ERA_GRANDFATHERED
	}

	if (!subscription.subscriptionInfo.hasAccessToPremiumFeatures && subscription.subscriptionInfo.hasBasic) {
		if (subscription.subscriptionInfo.churned) {
			return SubscriptionType.OPTIONAL_ERA_CHURNED
		}

		return SubscriptionType.OPTIONAL_ERA_GRANDFATHERED
	}

	if (subscription.subscriptionInfo.churned) {
		return SubscriptionType.NO_SUBSCRIPTION
	}

	if (!subscriptionName) {
		const createdAtDate = new Date(subscription.primarySubscription.createdAt)
		const JANUARY_2023 = new Date('2023-01-01T00:00:00Z')

		// Approximate, both of these cohorts should receive the same upgrade experience,
		// so the difference isn't vital.

		if (createdAtDate > JANUARY_2023) {
			if (subscription.primarySubscription.subscriptionTier === SubscriptionTier.Plus) {
				return SubscriptionType.MANDATORY_ERA_ENHANCED
			} else {
				return SubscriptionType.MANDATORY_ERA_STANDARD
			}
		} else {
			if (subscription.primarySubscription.subscriptionTier === SubscriptionTier.Plus) {
				return SubscriptionType.OPTIONAL_ERA_PRO
			} else {
				return SubscriptionType.OPTIONAL_ERA_STANDARD
			}
		}
	} else {
		// welcome to hell

		if (
			subscriptionName.includes('8+ standard') ||
			subscriptionName.includes('standard membership') ||
			(subscription.primarySubscription.provider === SubscriptionProvider.Recharge && subscription.primarySubscription.isActiveBasic === true)
		) {
			return SubscriptionType.OPTIONAL_ERA_STANDARD
		} else if (
			subscriptionName.includes('8plus pro') ||
			(subscriptionName.includes('8+ pro') && !subscriptionName.includes('monthly')) ||
			(subscription.primarySubscription.provider === SubscriptionProvider.Recharge && subscription.primarySubscription.isActivePremium === true)
		) {
			return SubscriptionType.OPTIONAL_ERA_PRO
		} else if (subscriptionName.includes('pro') || subscriptionName.includes('standard')) {
			return SubscriptionType.MANDATORY_ERA_STANDARD
		} else if (subscriptionName.includes('plus') || subscriptionName.includes('elite') || subscriptionName.includes('enhanced')) {
			return SubscriptionType.MANDATORY_ERA_ENHANCED
		} else if (subscriptionName.includes('temporary')) {
			return SubscriptionType.TEMPORARY
		}
	}

	return SubscriptionType.NO_SUBSCRIPTION
}

export const mapCapabilityToFeature = (capability: SubscriptionCapability): SubscriptionFeature | null | undefined => {
	switch (capability) {
		case 'temperature_control_with_timer':
			return SubscriptionFeature.TEMPERATURE_DIAL
		case 'temperature_scheduling':
			return SubscriptionFeature.TEMPERATURE_SCHEDULE
		case 'vibration_and_temperature_wake_up':
			return SubscriptionFeature.VIBRATION_AND_THERMAL_ALARM
		case 'sleep_and_health_reporting':
			return SubscriptionFeature.SLEEP_AND_HEALTH_REPORTS
		case 'autopilot':
			return SubscriptionFeature.AUTOMATIC_TEMPERATURE
		case 'insights':
			return SubscriptionFeature.SLEEP_AND_HEALTH_INSIGHTS
		case 'snoring_detection':
			return SubscriptionFeature.SNORING_DETECTION
		case 'snoring_mitigation':
			return SubscriptionFeature.SNORING_MITIGATION
		case 'multi_pod_support':
		case 'away_mode':
		case 'member_shop_and_referral':
		case 'device_setup':
		case 'partner_management_and_temperature_control':
		case 'sleep_content':
		case 'temperature_control_widget':
		case 'five_year_extended_warranty':
			return null
	}
}

export const convertSubscriptionCapabilitiesToFeatures = (capabilities: SubscriptionCapability[]): SubscriptionFeature[] => {
	const subscriptionFeatures = capabilities.map(mapCapabilityToFeature).filter((feature) => feature !== null && feature !== undefined)

	if (capabilities.includes('five_year_extended_warranty')) {
		subscriptionFeatures.push(SubscriptionFeature.FIVE_YEAR_WARRANTY)
	} else {
		subscriptionFeatures.push(SubscriptionFeature.TWO_YEAR_WARRANTY)
	}

	return subscriptionFeatures
}

/**
 * @deprecated Only use this if you're fetching a **new** plan, as in, one that the user doesn't currently have.
 * If you're trying to get the features the user currently has, use
 * `convertSubscriptionCapabilitiesToFeatures` with the `capabilities` field from the subscription object.
 * @param subscriptionType
 */
export const getSubscriptionFeatures = (subscriptionType: SubscriptionType): SubscriptionFeature[] => {
	switch (subscriptionType) {
		case SubscriptionType.PRE_SUB_ERA_GRANDFATHERED:
			return [
				SubscriptionFeature.TEMPERATURE_SCHEDULE,
				SubscriptionFeature.AUTOMATIC_TEMPERATURE,
				SubscriptionFeature.SLEEP_AND_HEALTH_REPORTS,
				SubscriptionFeature.SLEEP_AND_HEALTH_INSIGHTS,
				SubscriptionFeature.VIBRATION_AND_THERMAL_ALARM,
				SubscriptionFeature.TWO_YEAR_WARRANTY,
			]
		case SubscriptionType.OPTIONAL_ERA_GRANDFATHERED:
			return [SubscriptionFeature.TEMPERATURE_SCHEDULE, SubscriptionFeature.SLEEP_AND_HEALTH_REPORTS, SubscriptionFeature.VIBRATION_AND_THERMAL_ALARM, SubscriptionFeature.TWO_YEAR_WARRANTY]
		case SubscriptionType.OPTIONAL_ERA_CHURNED:
			return [SubscriptionFeature.TEMPERATURE_SCHEDULE, SubscriptionFeature.SLEEP_AND_HEALTH_REPORTS, SubscriptionFeature.VIBRATION_AND_THERMAL_ALARM, SubscriptionFeature.TWO_YEAR_WARRANTY]
		case SubscriptionType.OPTIONAL_ERA_STANDARD:
		case SubscriptionType.OPTIONAL_ERA_PRO:
			return [
				SubscriptionFeature.TEMPERATURE_SCHEDULE,
				SubscriptionFeature.AUTOMATIC_TEMPERATURE,
				SubscriptionFeature.SLEEP_AND_HEALTH_REPORTS,
				SubscriptionFeature.SLEEP_AND_HEALTH_INSIGHTS,
				SubscriptionFeature.VIBRATION_AND_THERMAL_ALARM,
				SubscriptionFeature.SNORING_DETECTION,
				SubscriptionFeature.SNORING_MITIGATION,
				SubscriptionFeature.TWO_YEAR_WARRANTY,
			]
		case SubscriptionType.MANDATORY_ERA_STANDARD:
		case SubscriptionType.TEMPORARY:
			return [
				SubscriptionFeature.TEMPERATURE_SCHEDULE,
				SubscriptionFeature.AUTOMATIC_TEMPERATURE,
				SubscriptionFeature.SLEEP_AND_HEALTH_REPORTS,
				SubscriptionFeature.SLEEP_AND_HEALTH_INSIGHTS,
				SubscriptionFeature.VIBRATION_AND_THERMAL_ALARM,
				SubscriptionFeature.SNORING_DETECTION,
				SubscriptionFeature.SNORING_MITIGATION,
				SubscriptionFeature.TWO_YEAR_WARRANTY,
			]
		case SubscriptionType.MANDATORY_ERA_ENHANCED:
			return [
				SubscriptionFeature.TEMPERATURE_SCHEDULE,
				SubscriptionFeature.AUTOMATIC_TEMPERATURE,
				SubscriptionFeature.SLEEP_AND_HEALTH_REPORTS,
				SubscriptionFeature.SLEEP_AND_HEALTH_INSIGHTS,
				SubscriptionFeature.VIBRATION_AND_THERMAL_ALARM,
				SubscriptionFeature.SNORING_DETECTION,
				SubscriptionFeature.SNORING_MITIGATION,
				SubscriptionFeature.FIVE_YEAR_WARRANTY,
			]
		case SubscriptionType.CUSTOM_GRANDFATHERED_TO_MANDATORY:
			return [SubscriptionFeature.AUTOMATIC_TEMPERATURE, SubscriptionFeature.VIBRATION_AND_THERMAL_ALARM, SubscriptionFeature.SLEEP_AND_HEALTH_REPORTS, SubscriptionFeature.SNORING_DETECTION]
		case SubscriptionType.CUSTOM_GRANDFATHERED_TO_ENHANCED:
			return [
				SubscriptionFeature.AUTOMATIC_TEMPERATURE,
				SubscriptionFeature.VIBRATION_AND_THERMAL_ALARM,
				SubscriptionFeature.SLEEP_AND_HEALTH_REPORTS,
				SubscriptionFeature.SNORING_DETECTION,
				SubscriptionFeature.EXTENDED_WARRANTY,
			]
		case SubscriptionType.NO_SUBSCRIPTION:
		default:
			return [SubscriptionFeature.TEMPERATURE_DIAL, SubscriptionFeature.TWO_YEAR_WARRANTY]
	}
}

export const getSubscriptionFeatureNiceName = (subscriptionFeature: SubscriptionFeature): string => {
	switch (subscriptionFeature) {
		case SubscriptionFeature.TEMPERATURE_SCHEDULE:
			return 'Temperature schedule'
		case SubscriptionFeature.TEMPERATURE_DIAL:
			return 'Temperature dial'
		case SubscriptionFeature.AUTOMATIC_TEMPERATURE:
			return 'Automatic  temperature'
		case SubscriptionFeature.SLEEP_AND_HEALTH_REPORTS:
			return 'Daily sleep and health report'
		case SubscriptionFeature.SLEEP_AND_HEALTH_INSIGHTS:
			return 'Sleep and health insights'
		case SubscriptionFeature.VIBRATION_AND_THERMAL_ALARM:
			return 'Vibration and thermal alarm'
		case SubscriptionFeature.SNORING_DETECTION:
			return 'Snoring detection'
		case SubscriptionFeature.SNORING_MITIGATION:
			return 'Snoring mitigation'
		case SubscriptionFeature.TWO_YEAR_WARRANTY:
			return '2-year warranty from date of delivery'
		case SubscriptionFeature.FIVE_YEAR_WARRANTY:
			return 'Extended 5-year warranty'
		case SubscriptionFeature.EXTENDED_WARRANTY:
			return '<strong>Extended warranty</strong>'
	}
}

export const getSubscriptionYearlyCost = (subscriptionType: SubscriptionType): Partial<Record<Currency, number>> => {
	switch (subscriptionType) {
		case SubscriptionType.OPTIONAL_ERA_STANDARD:
			return {
				USD: 96,
				GBP: 90,
				EUR: 108,
				CAD: 128,
				AUD: 154,
				SEK: 1280,
				DKK: 896,
			}
		case SubscriptionType.OPTIONAL_ERA_PRO:
			return {
				USD: 180,
				GBP: 168,
				EUR: 204,
				CAD: 240,
				AUD: 288,
				SEK: 2400,
				DKK: 1680,
			}
		case SubscriptionType.MANDATORY_ERA_STANDARD:
			return {
				USD: 199,
				GBP: 199,
				EUR: 199,
				CAD: 299,
				AUD: 299,
				SEK: 2199,
				DKK: 1399,
				AED: 780,
			}
		case SubscriptionType.MANDATORY_ERA_ENHANCED:
			return {
				USD: 299,
				GBP: 299,
				EUR: 299,
				CAD: 399,
				AUD: 499,
				SEK: 3299,
				DKK: 2099,
				AED: 1188,
			}
		case SubscriptionType.PRE_SUB_ERA_GRANDFATHERED:
		case SubscriptionType.OPTIONAL_ERA_GRANDFATHERED:
		case SubscriptionType.OPTIONAL_ERA_CHURNED:
		case SubscriptionType.NO_SUBSCRIPTION:
		case SubscriptionType.TEMPORARY:
		default:
			return {
				USD: 0,
				GBP: 0,
				EUR: 0,
				CAD: 0,
				AUD: 0,
				SEK: 0,
				DKK: 0,
				AED: 0,
			}
	}
}
