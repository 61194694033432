import { ZodError } from 'zod'

export const safeErrorMessage = (error: unknown): string => {
	if (error instanceof Error) {
		return error.message
	}
	return JSON.stringify(error) ?? 'An unknown error occurred'
}

export const safeError = (error: unknown): Error => {
	if (error instanceof Error) {
		return error
	}
	return new Error(safeErrorMessage(error))
}

export const zodErrorMessage = (error: ZodError): string => {
	return error.issues.map((issue) => issue.message).join(', ')
}
